<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Dispositivos</span>
      </v-col>
      <v-col>
        <v-btn disabled to="" icon small absolute right fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row
      ><v-col>
        <DispositivosFilter
          @dispositivos-changed="sendDispositivos"
          :pageNumber="pageNumber"
        >
        </DispositivosFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <DispositivosTable
          ref="dispositivosTable"
          :dispositivos="this.dispositivos.results"
          :dispositivosCount="this.dispositivos.count"
          @paginate="handlePagination"
        >
        </DispositivosTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DispositivosTable from "../components/DispositivosTable";
import DispositivosFilter from "../components/DispositivosFilter.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "Dispositivos",
  components: {
    DispositivosTable,
    DispositivosFilter,
  },
  data() {
    return {
      dispositivos: [],
      pageNumber: "1",
      loading: false,
    };
  },
  methods: {
    sendDispositivos(dispositivos) {
      // this.pageNumber = "1";
      this.dispositivos = dispositivos;
      this.$refs.dispositivosTable.loading = false;
    },

    handlePagination(pageNumber) {
      //console.log("paginate to: " + pageNumber);
      this.pageNumber = pageNumber;
    },
  },
};
</script>
